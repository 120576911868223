import ReactDOM from 'react-dom/client'
import App from './App'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import { RtlProvider } from './components/theme-components/RtlProvider'
import StoreProvider from './providers/StoreProvider'
import Fonts from './components/theme-components/Fonts'
import { theme } from './theme/theme'
import { QueryClientProvider } from 'react-query'
import { queryClient } from './utils/queryClient'
import { ReactQueryDevtools } from 'react-query/devtools'
import * as Sentry from '@sentry/browser'
import InterceptorComponent from './components/common/InterceptorComponent'

import { registerSW } from 'virtual:pwa-register'
// force app to reload when new update available without prompt
registerSW({ immediate: true })

Sentry.init({
  dsn: 'https://561ac02e5d1a4648adc7fdb3c98aa3f8@kar-sentry.karnameh.com/5',
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // <React.StrictMode>
  <BrowserRouter>
    <StoreProvider>
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Fonts />
          <RtlProvider>
            <InterceptorComponent />
            <App />
          </RtlProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ChakraProvider>
    </StoreProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
)
